import Api from './Api';
import Csrf from "./Csrf";


export default {
    async createProgramme(newProgrammeForm) {
        await Csrf.getCookie();
        return Api.post('/programme/create', newProgrammeForm);
    },

    async updateProgramme(id, updateData) {
        await Csrf.getCookie();
        return Api.patch('/programme/update/' + id, updateData);
    },

    async deleteProgramme(id) {
        await Csrf.getCookie();
        return Api.patch('/programme/destroy/' + id);
    },

    async getAllProgrammes(page, per_page = 20) {
        await Csrf.getCookie();
        return Api.get('/all-programmes/'+per_page+'?page=' + page);
    },

    async getAllProgrammesIdAndName() {
        await Csrf.getCookie();
        return Api.get('/all-programmes-id-and-name');
    },

    async getProgrammesByDepartmentID(id, page) {
        await Csrf.getCookie();
        return Api.get('/programmes/' + id + '?page=' + page);
    },

    async getAllowedProgrammesByDepartmentId(id, transfer_application_id) {
        await Csrf.getCookie();
        return Api.get('/allowed-transfer-programmes/' + id + '/' + transfer_application_id);
    },

    async getProgrammesByHost(data) {
        await Csrf.getCookie();
        return Api.post('/programmes/host', data);
    },

    async getProgrammesByHostAndInstituteId(data) {
        await Csrf.getCookie();
        return Api.post('/programmes/host-and-institute-id', data);
    },

    async getAllProgrammesInFaculty(faculty_id) {
        await Csrf.getCookie();
        return Api.get('/allProgrammes/faculty/' + faculty_id);
    },

    async getAllProgrammeList(institute_id = null) {
        await Csrf.getCookie();
        return Api.get('/all-programme-list/'+institute_id);
    },

    async getInstituteProgrammes(institute_id) {
        await Csrf.getCookie();
        return Api.get('/programmes/institutes/' + institute_id);
    },

    async getProgrammesByProgrammeLevelID(programme_level) {
        await Csrf.getCookie();
        return Api.get('/programmes/programme-level/' + programme_level);
    },

    async getProgrammesByAcademicProgrammeLevelIDAndInstitute(academic_programme_level_id, institute_id) {
        await Csrf.getCookie();
        return Api.get('/programmes/academic-programme-level/' + academic_programme_level_id + '/' + institute_id);
    },

    async getAdmissionRoundProgrammes(admission_round_id) {
        await Csrf.getCookie();
        return Api.get('/programmes/admission-round/' + admission_round_id);
    },

    async getProgrammesByDepartmentIDAndProgrammeLevelID(id, programme_level_id) {
        await Csrf.getCookie();
        return Api.get('/programmes/' + id + '/' + programme_level_id);
    },


    async getProgrammeByID(id) {
        await Csrf.getCookie();
        return Api.get('/programme/' + id);
    },

    async getProgrammeMaxOLevelSitting(programme_id, admission_round_id) {
        await Csrf.getCookie();
        return Api.get('/programme/max-o-level-sitting/' + programme_id + '/' + admission_round_id);
    },

    async updateProgrammeMaxOLevelSitting(data) {
        await Csrf.getCookie();
        return Api.post('/programme/max-o-level-sitting/update', data);
    },

    async getProgrammesInFaculty(faculty_id) {
        await Csrf.getCookie();
        return Api.get('/allProgrammes/faculty/' + faculty_id);
    },

    async getAllProgrammesInFacultyWithAdmissionStatusCount(admission_round_id, faculty_id) {
        await Csrf.getCookie();
        return Api.get('/allProgrammes-with-status/faculty/' + admission_round_id + '/' +faculty_id);
    },


    async getProgrammeCount() {
        await Csrf.getCookie();
        return Api.get('/count-all-programmes');
    },

    async getLecturerDepartmentalProgrammes() {
        await Csrf.getCookie();
        return Api.get('/all-lecturer-departmental-programmes');
    },

    async changeStudentsProgramme(newProgrammeForm) {
        await Csrf.getCookie();
        return Api.post('/change-of-programme/create', newProgrammeForm);
    },

    async loadProgrammesByDepartmentIds(department_ids){
        await Csrf.getCookie();
        return Api.get('/load-programmes-by-department-ids', { params: { department_ids }});
    },

    async createModeOfStudy(newModeOfStudyForm) {
        await Csrf.getCookie();
        return Api.post('programme/mode-of-study/create', newModeOfStudyForm);
    },

    async updateModeOfStudy(id, newModeOfStudyForm) {
        await Csrf.getCookie();
        return Api.patch('programme/mode-of-study/update/' + id, newModeOfStudyForm);
    },

    async deleteModeOfStudy(id) {
        await Csrf.getCookie();
        return Api.delete('programme/mode-of-study/destroy/' + id);
    },
};
