import Api from './Api';
import Csrf from "./Csrf";

export default {

    async loadData() {
        await Csrf.getCookie();
        return Api.get('/payments');
    },
    async getSuccessfulPayments(page) {
        await Csrf.getCookie();
        return Api.get('/successful-payment?page=' + page);
    },

    async getFeePlanAllocationAllocatedToStudent() {
        await Csrf.getCookie();
        return Api.get('/allocated-fee-plan-to-student');
    },

    async getFeePlanAllocationAllocatedToStudentBySession(session_id) {
        await Csrf.getCookie();
        return Api.get('/get-fee-plan-allocated-to-student-by-session/' + session_id);
    },

    async getPaymentCount() {
        await Csrf.getCookie();
        return Api.get('/payments/count');
    },

    async createPendingPayment(formData) {
        await Csrf.getCookie();
        return Api.post('/pending-payment/create', formData);
    },

    async createPendingPlatinumPayment(formData) {
        await Csrf.getCookie();
        return Api.post('/pending-platinum-payment/create', formData);
    },

    async createPendingRemitaPayment(formData) {
        await Csrf.getCookie();
        return Api.post('/pending-remita-payment/create', formData);
    },

    async validateRRRInvoice(data) {
        await Csrf.getCookie();
        return Api.post('/payment/remita-invoice/validate', data);
    },

    async getStudentGeneratedRRRForAPaymentPurpose(purpose, sessionId) {
        await Csrf.getCookie();
        return Api.get('/payment-reference/get-student-rrr/' + purpose + '/' + sessionId);
    },

    async getOverallPaymentsBySession(id) {
        await Csrf.getCookie();
        return Api.get('/payments/overall/' + id);
    },

    async getOverallPaymentsByRange(sessionId, startDate, endDate, level_id, institute_id) {
        await Csrf.getCookie();
        return Api.get('/payments/overall/range/' + sessionId + '/' + startDate + '/' + endDate + '/' + level_id + '/' + institute_id);
    },

    async getSuccessfulPaymentsByPurpose(sessionId, selectedPurpose) {
        await Csrf.getCookie();
        return Api.get('/successful-payment/fiter-by-purpose/' + sessionId + '/' + selectedPurpose);
    },

    async getSuccessfulPaymentsByPurposeAndRange(sessionId, selectedPurpose, startDate, endDate, page) {

        await Csrf.getCookie();
        return Api.get('/successful-payment/fiter-by-purpose/range/' + sessionId + '/' + selectedPurpose + '/' + startDate + '/' + endDate + '?page=' + page);
    },

    async downloadPaymentDashboardReports(sessionId, startDate, endDate, level_id, institute_id) {

        return Api.get('/payments/dasboard/reports/' + sessionId + '/' + startDate + '/' + endDate + '/' + level_id + '/' + institute_id, { responseType: 'blob' });
    },

    async candidateTransactionHistory(formData) {
        await Csrf.getCookie();
        // Convert the form data to a query string
        const queryString = Object.keys(formData)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`)
            .join('&');
        const url = `/get-candidate-transaction-history-for-validation?${queryString}`;
        return Api.get(url);
    },

    async applicantTransactionHistory(formData) {
        await Csrf.getCookie();
        // Convert the form data to a query string
        const queryString = Object.keys(formData)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`)
            .join('&');
        const url = `/get-applicant-transaction-history-for-validation?${queryString}`;
        return Api.get(url);
    },

    async makePaystackPayment(formData) {
        await Csrf.getCookie();
        return Api.post('/pay/student/paystack-payment', formData);
    },

    async createPendingPaystackPayment(formData) {
        await Csrf.getCookie();
        return Api.post('/pending-paystack-payment/create', formData);
    },

    async updatePaymentStatus(formData) {
        await Csrf.getCookie();
        return Api.patch('/update-payment-status', formData);
    },

    async updatePlatinumPaymentStatus(formData) {
        await Csrf.getCookie();
        return Api.patch('/update-platinum-payment-status', formData);
    },

    async generateUniqueReferenceNumber() {
        await Csrf.getCookie();
        return Api.get('/generate-unique-reference-number');
    },

    async verifyPaystackTransaction(rrr, secret_key) {
        await Csrf.getCookie();
        return Api.get('/verify-paystack-payment/' + rrr + '/' + secret_key);
    },

    async verifyTransaction(rrr) {
        await Csrf.getCookie();
        return Api.get('/verify-payment/' + rrr);
    },

    async verifyPortalAccessFeePayment(student_id, session_id, semester_id = null) {
        await Csrf.getCookie();
        return Api.get('/verify-portal-access-fee/' + student_id + '/' + session_id + '/' + semester_id + '/payment');
    },

    async getPaymentProcessingCharges(institute_id = null) {
        await Csrf.getCookie();
        return Api.get('/get-institutional-processing-charges/' + institute_id);
    },

    async getPaymentProcessingChargeByPurpose(purpose, institute_id = null) {
        await Csrf.getCookie();
        return Api.get('/get-institutional-processing-charges-by-purpose/' + purpose + '/' + institute_id);
    },

    async defineHostelPoolPreferredGateway(formData) {
        await Csrf.getCookie();
        return Api.post('/hostel-pool-gateway-definition', formData)
    },

    async defineChangeOfProgrammePreferredGateway(formData) {
        await Csrf.getCookie();
        return Api.post('/change-of-programme-gateway-definition', formData)
    },

    async getCurrentSessionHostelPoolGateway() {
        await Csrf.getCookie();
        return Api.get('/get-hostel-pool-gateway');
    },

    async getCurrentSessionChangOfProgrammeGateway() {
        await Csrf.getCookie();
        return Api.get('/get-change-of-programme-gateway');
    },

    async getAllPaymentPurposesPerSession(session_id) {
        await Csrf.getCookie();
        return Api.get(`/payment-purposes/session/${session_id}`);
    },

    async deleteChangOfProgrammeGateway(id) {
        await Csrf.getCookie();
        return Api.delete('/delete-change-of-programme-gateway/' + id);
    },

    async downloadReport(data) {
        return Api.post('/download-payment-report', data, { responseType: 'blob' });
    },

    async getSubAccountsByInstituteId(payment_gateway, institute_id=null) {
        await Csrf.getCookie();
        return Api.get("/sub-accounts-by-institute/" + payment_gateway + "/" + institute_id);
    },

    async getMerchantWithSubAccountsByInstituteId(payment_gateway, institute_id=null) {
        await Csrf.getCookie();
        return Api.get("/merchant-with-sub-accounts-by-institute/" + payment_gateway + "/" + institute_id);
    },

    async generateCSV(purpose, session = null){
        await Csrf.getCookie();
        return Api.get('/download-payment-reports/' + session + '/' + purpose);
    },
}
