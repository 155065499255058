<template>
  <v-dialog v-model="show_portal_access_fee_dialog" persistent max-width="400px" overlay-opacity="0.6">
    <v-card>
      <v-card-title class="headline">Payment Required</v-card-title>
      <v-card-text style="color: black;">
        You have not made your Portal Access Fee payment. You cannot proceed with your student activities until you do.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="cancel-btn" text @click="logout()" :disabled="cancel_button_disabled">Logout</v-btn>
        <v-btn class="submit-btn" text :loading="create_update_loading" @click="proceedToPayment()">
          <span class="submit-btn">Proceed</span>
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UserAPI from "../apis/User";
import { useUserStore } from '../stores/userStore';
import PortalSubscriptionFeeAPI from '../apis/PortalSubscriptionFee';
export default {
  props: ['paid_portal_access_fee', 'current_session', 'pay_portal_access_fee_at_login','student'],
  data() {
    return {
      show_portal_access_fee_dialog: false,
      purpose: "Portal Access Fee",
      loading: false,
      current_portal_subscription: {},
    };
  },
  watch: {
    paid_portal_access_fee(value) {
        if (!value) {
          this.show_portal_access_fee_dialog = true;
        }
    },
    combinedWatchValue(newValues) {
      if (newValues[0] && newValues[1] !== null && !newValues[2]) {
        this.getCurrentPortalSubscriptionFee();
      }
    }
  },
  computed: {
    combinedWatchValue() {
      if (this.pay_portal_access_fee_at_login && this.current_session && !this.paid_portal_access_fee) {
        return [true, this.current_session, false];
      }
      return [false, null, true]; 
    }
  },
  methods: {
     proceedToPayment() {
      this.create_update_loading = true;
      this.$router.push({
        name: 'Payment Template',
        params: {
          totalCharges: this.current_portal_subscription.amount,
          purpose: this.purpose,
          gateway: this.current_portal_subscription.payment_gateway,
          payer_type: 'Student',
          payer_object: this.student,
          payable_type: 'PortalSubscriptionFee',
          payable_id: this.current_session.id,
          redirect_url: '/student',
          paystack_sub_account: this.current_portal_subscription.sub_account.sub_account_name,
          fundable_id: this.current_portal_subscription.sub_account_id,
          fundable_type: this.current_portal_subscription.sub_account_type,
          session_id: this.current_session.id,
          rebate_id: null,
          vired_fee_plan_items: [],
          instalment_id: null,
          instalment_percentage: null,
          should_pay_portal_access_fee_at_login: this.pay_portal_access_fee_at_login
        },
      });
      this.create_update_loading = false;
    },
    switchComponent(comp) {
    this.$router.push({ name: comp });
   },
    logout() {   
        // show_modal = false;
    UserAPI.logout()
      .then(() => {
        useUserStore().$patch(null);
        this.switchComponent("Login");
      });
    },
    getCurrentPortalSubscriptionFee() {
      this.create_update_loading = true;
      const id = this.current_session.id
      PortalSubscriptionFeeAPI.getCurrentPortalSubscriptionFees(id)
        .then((response) => {
          this.current_portal_subscription = response.data.current_subscription_fee;
        })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.create_update_loading = false;
      });
  }
  },
};
</script>

<style scoped>
.v-dialog__content {
  backdrop-filter: blur(5px);
}
</style>
