import Api from './Api';
import Csrf from "../apis/Csrf";

export default {
    async createRole(newRoleForm) {
        await Csrf.getCookie();
        return Api.post('/createRole', newRoleForm);
    },

    async getAllRoles() {
        await Csrf.getCookie();
        return Api.get('/roles');
    },

    async fetchAllRoles() {
        await Csrf.getCookie();
        return Api.get('/all-roles');
    },

    async getAllRolesForAdmissionRecommendations() {
        await Csrf.getCookie();
        return Api.get('/all-roles-for-admission-recommendation');
    },

    async getInstitutionalRoles(page) {
        await Csrf.getCookie();
        return Api.get('/roles?page='+page);
    },

    async assignRole(requestData) {
     await Csrf.getCookie();
     return Api.patch('/role/assign', requestData);
    },

    async removeRole(requestData) {
        await Csrf.getCookie();
        return Api.patch('/role/remove', requestData);
    },

    async switch(role_name) {
        await Csrf.getCookie();
        const requestData = {
            role: role_name
        };
        return Api.post('/role/switch', requestData);
    },
};