<template>
    <v-app id="inspire">
        <v-sheet color="white" height="600" align="center" justify="center" dark>
            <v-container>
                <v-avatar color="indigo" size="200" style="margin-top: 120px">
                    <img :src="staff.picture_url" alt=""
                        v-if="staff && staff.picture_url">
                    <img src="images/profile_male.png" alt="" v-else-if="staff && staff.gender === 'Male'">
                    <img src="images/profile_female.png" alt="" v-else>
                </v-avatar>
                <div>
                    <v-list-item color="black" two-line v-if="staff">
                        <v-list-item-content>
                            <v-list-item-title class="headline black--text">{{ staff.title }} {{ staff.firstname }}
                                {{ staff.lastname }} [{{ staff.staff_number }}]</v-list-item-title>
                            <v-list-item-subtitle class="black--text">{{ staff.rank ?
                                staff.appointment_type + ' ' +  staff.rank.name : ''}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <router-link :to="{ name: 'Support Staff Profile', params: { user: staff } }" v-if="staff">
                        <v-btn class="mt-5" light depressed color="primary"> View Profile </v-btn>
                    </router-link>
                </div>
            </v-container>
        </v-sheet>
    </v-app>
</template>

<script>
import OverviewCards from "../../../OverviewCards";
import UserAPI from "../../../../apis/User";

import TimeTableAPI from "../../../../apis/TimeTable";
import TimeTableLectureAPI from "../../../../apis/TimeTableLecture";
import SessionAPI from "../../../../apis/AcademicSession";
import CourseAllocationAPI from "../../../../apis/CourseAllocation";

export default {
    name: "Overview",
    components: { OverviewCards },

    data() {
        return {
            staff: null,
            search: '',
            currentSemester: null,
        }
    },
    mounted() {
        this.initialize();
    },

    methods: {
        initialize() {
            UserAPI.auth().then(response => {
                this.staff = response.data.data;
            });

            //------------------------------------------------------------------------------------------------------------
            SessionAPI.getAllSemesters()
                .then(response => {
                    this.currentSemester = response.data.data.find(semester => semester.status === 'current');
                })
                .catch(error => {
                    console.log(error);
                });
        },

        switchComponent(comp) {
            this.$router.push({ name: comp });
        },
    },
}
</script>

<style></style>
