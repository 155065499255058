<template>
  <v-app id="inspire">
     <v-main>
      <Navigation
        :authenticatedUser="userStore.auth"
      >
        <template v-slot:items>
          <SiderBarItems
            :items="side_nav_items"
            :roles="userStore.getRoles"
            :permissions="userStore.getPermissions"
            usertype="lecturer"
            environment="main"
          />
        </template>
      </Navigation>
      <template>
          <Overview  v-if="$route.name === 'Lecturer'"></Overview>
          <router-view  :key="$route.fullPath"/>
      </template>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from '../../../Navigation_modules/CustomNavigationDrawer'
import SiderBarItems from '../../../Navigation_modules/SideBarItems'
import Overview from '../Home/Overview.vue'

export default {
    name:"LecturerDashboard",
    components: { Navigation, Overview, SiderBarItems},
    data() {
        return {
          side_nav_items: [],
        }
    },
    created() {
      this.setSidenavMenu(this.userStore.getSubdomain);
    },

    methods:{
      setSidenavMenu(subdomain){
        this.side_nav_items= [
          { text: 'Home', icon: 'dashboard', route: '' , permissions: []},
            { text: 'Courses', icon: 'courses', route: 'my-courses', permissions: []},
            { text: 'Lectures', icon: 'lectures', route: 'time-table', permissions: [] },
            { text: 'Learned Conferences', icon: 'courses', route: 'learned-conferences', permissions: [] },
            { text: "Announcements", icon: "assignments", route: "announcements", permissions: []},
        //    { text: 'E-Library', icon: 'mdi-bookshelf', route: 'e-library' },

            // { text: "LMS Home", icon: "lms", href:"http://63.35.197.135/", permissions: [] },
            // { text: 'CBT', icon: 'dashboard', route: 'cbt', permissions: [] },
            // { text: 'Inspired Home', icon: 'ahea-sm', href: "https://"+subdomain+"."+process.env.MIX_INSPIRED_HOME+"?token="+sessionStorage.getItem('access_token'), permissions: [],
            //   sub_items:[
            //       { text: 'Learning Management', icon: 'learning-management', href: "https://"+subdomain+"."+process.env.MIX_INSPIRED_LM+"?token="+sessionStorage.getItem('access_token') , permissions: []},
            //       { text: 'Collaboration Projects', icon: 'collaboration-projects', href: "https://"+subdomain+"."+process.env.MIX_INSPIRED_PROJECTS+"?token="+sessionStorage.getItem('access_token') , permissions: []},
            //       { text: 'More Info', icon: 'more-info', route: 'inspired' , permissions: []}
            //     ]
            // },
            // { text: 'My Documents', icon: 'courses', route: 'lecturer-assignments', permissions: []},
            { text: "Tickets" , icon: "ticket-outline", route: "tickets", permissions: [] },
        ];
      }
    }

}
</script>

<style>

</style>>
