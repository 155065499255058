<template>
  <div class="container mx-md-70 mx-3 my-4">
    <div>
      <v-list-item two-line v-if="department_name">
        <v-list-item-content>
          <v-list-item-title class="headline">{{
            department_name
          }}</v-list-item-title>
          <v-list-item-title>{{
            faculty_name
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>

    <OverviewCards :department_id="department_id" v-if="department_id"></OverviewCards>
  </div>
</template>

<script>
import OverviewCards from "./DepartmentalOverviewCards";
import UserAPI from "../../../apis/User";

export default {
  name: "DepartmentOverview",
  components: {
    OverviewCards,
  },
  data() {
    return {
      authenticatedUser: null,
      department_name: null,
      department_id: null,
      faculty_name: null,
      faculty_id: null,
    };
  },

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.department_name = this.hodStore.department_name;
      this.department_id = this.hodStore.department_id;
      this.faculty_name = this.hodStore.faculty_name;
      // UserAPI.auth().then((response) => {
      //   //retrieve the institution_id of the current user
      //   this.authenticatedUser = response.data.data;
      // });
    },
  },
};
</script>
