
import { defineStore } from 'pinia';

export const useHodStore = defineStore('hod', {
  state: () => ({
    department_id: null,
    department_name: null,
    faculty_id: null,
    faculty_name: null,
    role_id: null,
  }),
  actions: {
   setValues(department_id = null, department_name = null, faculty_id = null, faculty_name = null, role_id = null) {
        this.department_id = department_id;
        this.department_name = department_name;
        this.faculty_id = faculty_id;
        this.faculty_name = faculty_name;
        this.role_id = role_id;
    },
  },
  getters: {
    getDepartmentId() {
          return this.department_id
      },
      getDepartmentName() {
          return this.department_name
      },
      getFacultyId() {
        return this.faculty_id
    },
    getFacultyName() {
        return this.faculty_name
    },
    getRoleID() {
      return this.role_id
  },
  },
});
